/*
 * When animated elms are in view, redo animation
 */

function reanimate(elm){
    let type = elm.classList.toString().split(' ').filter(item => item.includes('animate__wait__'))[0];
    if(type){
        elm.classList.replace(type, type.replace('animate__wait__', 'animate__'));
    }
}
function wait(elm){
    let type = elm.classList.toString().split(' ').filter(item => item.includes('animate__') && !['animate__animated', 'animate__delay'].includes(item))[0];
    if(type) {
        if (!elm.classList.toString().includes('animate__wait')) {
            elm.classList.replace(type, type.replace('animate__', 'animate__wait__'));
        }
    }
}

export default function animate(){
    document.addEventListener('scroll', function(){

        for(let animated of document.querySelectorAll('.animate__animated')){
            let bounding = animated.getBoundingClientRect();

            if (bounding.bottom >= 0 && bounding.top <= (window.innerHeight || document.documentElement.clientHeight)) {
                reanimate(animated);
            }else if(!animated.classList.toString().includes('animate__wait__')){
                wait(animated);
            }
        }
    })

}
