<template>
  <div class="container flex max-sm:flex-col py-100 max-md:py-20" :class="{'min-md:flex-row-reverse': inverse}">
    <div class="min-md:w-1/2">
      <div class="sticky overflow-x-hidden top-0 py-50">
        <slot name="introduction"></slot>
      </div>
    </div>
    <div class="spacer lg:w-100 xl:w-150 flex-none"></div>
    <div class="min-md:w-1/2 text-red-full">
      <div class="sticky top-0">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  props:['inverse']
}
</script>

<style scoped>
.spacer{
  width:50px;
  @apply lg:w-100 xl:w-150;
}
</style>
