<template>
  <svg viewBox="0 0 88 129" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <circle fill="#50E3C2" cx="44" cy="12" r="10"></circle>
      <circle fill="#50E3C2" cx="44" cy="65" r="10"></circle>
      <circle fill="#50E3C2" cx="44" cy="118" r="10"></circle>
      <circle fill="#50E3C2" cx="76" cy="106" r="10"></circle>
      <circle fill="#50E3C2" cx="76" cy="73" r="10"></circle>
      <circle fill="#50E3C2" cx="76" cy="24" r="10"></circle>
      <circle fill="#50E3C2" cx="12" cy="24" r="10"></circle>
      <circle fill="#50E3C2" cx="12" cy="57" r="10"></circle>
      <circle fill="#50E3C2" cx="12" cy="106" r="10"></circle>
    </g>
  </svg>
</template>

<style lang="scss" scoped>
svg{
  width:88px;
  fill: currentColor;

  g, circle{
    fill: inherit;
  }
}
</style>
