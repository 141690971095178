<template>
  <div class="container h-0 top-0 flex z-1">
    <div @click="backOrHome" class="cursor-pointer absolute text-white article__paragraph text-sm animate animate__animated animate__fadeIn pt-60 max-sm:hidden">
      <span v-if="$route.name === 'home'">
        Stemmingmakers
      </span>
      <span v-else>
        <i class="far fa-arrow-left"></i>
        Home
      </span>
    </div>
    <router-link to="/" class="absolute center-x top-0 mt-50 text-white">
      <logo class="animate animate__animated animate__fadeIn article__paragraph"></logo>
    </router-link>
    <a href="/#contact" class="btn text-sm absolute right-0 mt-50 text-white article__paragraph animate animate__animated animate__fadeIn max-sm:hidden" style="right:20px;">Contact</a>
  </div>
</template>

<script>
import Logo from "@/components/SmLogo";
export default{
  components:{
    Logo
  },
  methods:{
    backOrHome(){
      if(this.$route.name !== 'home' && window.history.length > 2 && window.history.state.forward === '/'){
        this.$router.go(-1);
      }else{
        this.$router.push('/');
      }
    }
  }
}
</script>
