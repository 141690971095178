<template>
  <div id=contact class="max-sm:pb-50">
    <sticky-box>
      <template v-slot:introduction>
        <h3 class="text-grey article__paragraph text-xxl mb-20 animate__animated animate__fadeInLeft">Aangenaam kennis maken</h3>
        <span class="text-grey article__paragraph text-sm inline-block animate__animated animate__fadeInLeft">Gedegen onderzoek, door een jong ambitieus team met de allerbeste connecties met jongeren. Waar vind je dat? Nou, bij Stemmingmakers. Aangenaam kennis maken.</span>
      </template>
      <template v-slot:content>
        <div class="flex items-center min-md:mt-150 animate__animated animate__fadeInRight">
          <img src="./../assets/images/roos.jpg" alt="" class="avatar flex-none mr-40 max-md:mr-20">
          <div class="text-md md:leading-1 article__paragraph">
            Roos Smetsers<br>
            <a href="tel:030 2721378" class="max-lg:text-20 underline hover:text-purple">030 2721378</a><br>
            <a href="mailto:roos@stemmingmakers.nl" class="max-lg:text-20 underline hover:text-purple">roos@stemmingmakers.nl</a>
          </div>
        </div>
      </template>
    </sticky-box>
  </div>
</template>

<style lang="scss" scoped>

</style>
