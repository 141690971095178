import { createRouter, createWebHistory } from 'vue-router'
import Home from "@/components/Home";
import Article from "@/components/Article";

let routes = [
    {
        path:      '/',
        name:      'home',
        component: Home
    },
    {
        path:      '/contact',
        name: 'redirect',
        alias: ['/projecten', '/author/roos', '/tag/scholieren', '/tag/stemmingmakers', '/over-ons', '/tag/trends'],
        redirect: "/",
    },
    {
        path:      '/:slug',
        name:      'article',
        component: Article
    },
];

let initialLoad = true;

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        initialLoad = false;
        if(!initialLoad && to.fullPath.split('#')[0] !== from.fullPath.split('#')[0]) {
            if(to.name === 'article' && from.name === 'article'){
                // Router-view with key makes blog>blog possible but kills animation so for this purpose animation is added customly
                document.getElementById('app').setAttribute('class', 'fade-enter-active fade-leave-to');
            }
            return new Promise((resolve) => {
                setTimeout(() => {
                    // Keep scroll height
                    if (to.name !== 'article' && savedPosition) {
                        resolve(savedPosition)
                    } else {
                        resolve({top: 0});
                    }
                }, 500);
            });
        }
    },
})
export default router
